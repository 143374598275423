import React, { ReactNode } from "react"
import { Close } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { SnackbarKey, SnackbarProvider } from "notistack"

type NotificationProviderProps = {
  children: ReactNode
}

const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const notistackRef = React.createRef<any>()
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      hideIconVariant
      action={key => (
        <IconButton onClick={onClickDismiss(key)}>
          <Close />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  )
}

export default NotificationProvider
