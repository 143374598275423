import React from "react"
import posthog from "posthog-js"
import { Outlet, useLocation } from "react-router-dom"

const PosthogTracker: React.FC = () => {
  const location = useLocation()

  React.useEffect(() => {
    posthog.capture("$pageview")
  }, [location])

  return <Outlet />
}

export default PosthogTracker
