import React from "react"
import * as Sentry from "@sentry/react"
import { createBrowserRouter, Navigate } from "react-router-dom"
import GuestRoute from "components/auth/GuestRoute"
import PrivateRoute from "components/auth/PrivateRoute"
import RouterError from "components/layout/RouterError"
import PosthogTracker from "components/PosthogTracker"

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    element: <PosthogTracker />,
    errorElement: <RouterError />,
    children: [
      {
        element: <GuestRoute />,
        children: [
          {
            path: "/login",
            lazy: async () => ({
              Component: (await import("pages/auth/Login")).default,
            }),
          },
          {
            path: "/register",
            lazy: async () => ({
              Component: (await import("pages/auth/Register")).default,
            }),
          },
          {
            path: "/forgot-password",
            lazy: async () => ({
              Component: (await import("pages/auth/ForgotPassword")).default,
            }),
          },
          {
            path: "/password-reset/:token",
            lazy: async () => ({
              Component: (await import("pages/auth/PasswordReset")).default,
            }),
          },
        ],
      },
      {
        element: <PrivateRoute />,
        errorElement: <RouterError />,
        children: [
          {
            index: true,
            element: <Navigate to={"/workspaces"} />,
          },
          {
            path: "workspaces",
            errorElement: <RouterError />,
            children: [
              {
                index: true,
                lazy: async () => ({
                  Component: (await import("pages/workspaces/Workspaces"))
                    .default,
                }),
              },
              {
                path: "create",
                lazy: async () => ({
                  Component: (await import("pages/workspaces/WorkspaceCreate"))
                    .default,
                }),
              },
              {
                path: ":workspaceId",
                errorElement: <RouterError />,
                children: [
                  {
                    index: true,
                    lazy: async () => ({
                      Component: (await import("pages/workspaces/Workspace"))
                        .default,
                    }),
                    errorElement: <RouterError />,
                  },
                  {
                    path: "new",
                    lazy: async () => ({
                      Component: (await import("pages/workspaces/WorkspaceNew"))
                        .default,
                    }),
                  },
                  {
                    path: "chats",
                    children: [
                      {
                        index: true,
                        lazy: async () => ({
                          Component: (await import("pages/chats/NewChat"))
                            .default,
                        }),
                      },
                      {
                        path: ":chatId",
                        lazy: async () => ({
                          Component: (await import("pages/chats/Chat")).default,
                        }),
                      },
                    ],
                  },
                  {
                    path: "settings",
                    children: [
                      {
                        index: true,
                        lazy: async () => ({
                          Component: (await import("pages/settings/Settings"))
                            .default,
                        }),
                      },
                      {
                        path: "workspace",
                        lazy: async () => ({
                          Component: (
                            await import("pages/settings/WorkspaceSettings")
                          ).default,
                        }),
                      },
                      {
                        path: "profile",
                        lazy: async () => ({
                          Component: (
                            await import("pages/settings/ProfileSettings")
                          ).default,
                        }),
                      },
                      {
                        path: "sources",
                        children: [
                          {
                            index: true,
                            lazy: async () => ({
                              Component: (
                                await import("pages/settings/sources/Sources")
                              ).default,
                            }),
                            errorElement: <RouterError />,
                          },
                          {
                            path: "create",
                            lazy: async () => ({
                              Component: (
                                await import(
                                  "pages/settings/sources/SourceCreate"
                                )
                              ).default,
                            }),
                          },
                          {
                            path: ":sourceId",
                            lazy: async () => ({
                              Component: (
                                await import("pages/settings/sources/Source")
                              ).default,
                            }),
                          },
                        ],
                      },
                      {
                        path: "memberships",
                        lazy: async () => ({
                          Component: (
                            await import(
                              "pages/settings/memberships/Memberships"
                            )
                          ).default,
                        }),
                      },
                    ],
                  },
                  {
                    path: "dashboards",
                    children: [
                      {
                        index: true,
                        lazy: async () => ({
                          Component: (
                            await import("pages/dashboards/Dashboards")
                          ).default,
                        }),
                        errorElement: <RouterError />,
                      },
                      {
                        path: ":dashboardId",
                        lazy: async () => ({
                          Component: (
                            await import("pages/dashboards/Dashboard")
                          ).default,
                        }),
                      },
                    ],
                  },
                  {
                    path: "metrics",
                    children: [
                      {
                        index: true,
                        lazy: async () => ({
                          Component: (await import("pages/metrics/Metrics"))
                            .default,
                        }),
                        errorElement: <RouterError />,
                      },
                    ],
                  },
                  {
                    path: "questions",
                    children: [
                      {
                        index: true,
                        lazy: async () => ({
                          Component: (await import("pages/questions/Questions"))
                            .default,
                        }),
                        errorElement: <RouterError />,
                      },
                      {
                        path: "create",
                        lazy: async () => ({
                          Component: (
                            await import("pages/questions/QuestionCreate")
                          ).default,
                        }),
                        errorElement: <RouterError />,
                      },
                      {
                        path: ":questionId",
                        lazy: async () => ({
                          Component: (await import("pages/questions/Question"))
                            .default,
                        }),
                        errorElement: <RouterError />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "oauth",
            children: [
              {
                path: "hubspot",
                lazy: async () => ({
                  Component: (await import("pages/oauth/Hubspot")).default,
                }),
              },
            ],
          },
        ],
      },
    ],
  },
])

export default router
