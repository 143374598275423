import { alpha, createTheme } from "@mui/material/styles"

// declare module '@material-ui/core/styles/createPalette' {
//   interface Palette {
//       pgrey: Palette['primary']
//   }
//   interface PaletteOptions {
//       pgrey: PaletteOptions['primary']
//   }
// }

const theme = createTheme({
  typography: {
    fontFamily: `"Sora", "Satoshi", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
  palette: {
    primary: {
      main: "#964D3D",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFB567",
    },
    success: {
      main: "#31C48D",
      contrastText: "#1F2A37",
    },
    error: {
      main: "#F05252",
      contrastText: "#1F2A37",
    },
    // pgrey: {
    //     main: '#aaabb8',
    // },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "inherit",
        },
        containedPrimary: {
          "&:hover": {
            backgroundColor: "#000000",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "inherit",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& th": {
            fontWeight: 700,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorSuccess: theme => ({
          backgroundColor: alpha(theme.theme.palette.success.main, 0.24),
        }),
        colorError: theme => ({
          backgroundColor: alpha(theme.theme.palette.error.main, 0.24),
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: theme => ({
          borderRadius: 8,
          " .MuiSvgIcon-root": {
            color: "#000000",
          },
          " .MuiIconButton-colorPrimary .MuiSvgIcon-root": {
            color: theme.theme.palette.primary.main,
          },
          " .MuiIconButton-colorError .MuiSvgIcon-root": {
            color: theme.theme.palette.error.main,
          },
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          "&:hover": {
            borderRadius: 6,
            backgroundColor: "rgba(0, 0, 0, 0.08)", // Customize hover color if needed
          },
          "&.Mui-selected": {
            borderRadius: 6,
            backgroundColor: "rgba(0, 0, 0, 0.14)", // Customize selected color if needed
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.20)", // Customize hover color when selected if needed
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: "#000000",
          " .MuiSvgIcon-root": {
            color: "#000000",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: theme => ({
          border: "1px solid",
          borderColor: theme.theme.palette.grey[400],
        }),
      },
    },
  },
})

export default theme
