import React from "react"
import { Box, Typography } from "@mui/material"
import { useRouteError } from "react-router-dom"
import NotFound from "./NotFound"

interface ErrorWithStatus extends Error {
  status?: number
}

const RouterError: React.FC = () => {
  const error = useRouteError() as ErrorWithStatus
  console.error(error)

  if (error.status === 404) return <NotFound />

  return (
    <Box
      sx={{
        textAlign: "center",
        pt: 20,
      }}
    >
      <Typography variant="h4">Oops - an error has occurred!</Typography>
    </Box>
  )
}

export default RouterError
