import "sentry"
import "posthog"
import React from "react"
import { createRoot } from "react-dom/client"
import App from "App"

window.addEventListener("vite:preloadError", () => {
  window.location.reload()
})

const container = document.getElementById("root")
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
