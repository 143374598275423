import posthog from "posthog-js"

const posthogToken = import.meta.env.VITE_POSTHOG_TOKEN
const api_host = import.meta.env.VITE_POSTHOG_HOST ?? "https://eu.i.posthog.com"

if (posthogToken) {
  posthog.init(posthogToken, {
    api_host,
    ui_host: "https://eu.i.posthog.com",
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      },
    },
  })
}
