import React from "react"
import { Box, Typography } from "@mui/material"

const NotFound: React.FC = () => (
  <Box
    sx={{
      textAlign: "center",
      pt: 20,
    }}
  >
    <Typography variant="h4">
      Oops - we couldn&apos;t find that page!
    </Typography>
  </Box>
)

export default NotFound
